import * as React from 'react';
import PageHeader from "../components/PageHeader";
import Copyright from "../components/Copyright";
import {Box, Container} from "@mui/material";
import PageDefault from "../components/PageDefault";

export default function Imprint() {
    return (
        <>
            <PageDefault/>
            <PageHeader/>
            <Container maxWidth={"md"}>
                <Box pb={4} pt={4}>
                    <h1>Impressum</h1>
                    <h2>Angaben gemäß § 5 TMG:</h2>
                    <p><br/>Christian Wilhelm</p>
                    <h3>Postanschrift:</h3>
                    <p>Wörthstraße 96/2<br/>89077 Ulm<br/></p>
                    <h3>Kontakt:</h3>
                    <p>Telefon: 073185072018<br/>E-Mail: info@groupmate-app.com</p>
                    <p></p><h3>Umsatzsteuer-Identifikationsnummer</h3>
                    <p>DE342514557<br/></p>
                    <p><br/></p>
                    <p></p><h2>Hinweise zur Website</h2>
                    <p></p><h3>Verantwortlich für journalistisch-redaktionelle Inhalte ist:</h3>
                    <p>Christian Wilhelm<br/></p>
                    <p></p><h2>Information gemäß § 36 VSBG</h2>
                    <p>Gemäß § 36 VSBG (Verbraucherstreitbeilegungsgesetz – Gesetz über die alternative Streitbeilegung
                        in Verbrauchersachen) erklärt der Betreiber dieser Website:</p>
                    <p>Wir sind weder bereit noch verpflichtet, an Streitbeilegungsverfahren vor einer
                        Verbraucherschlichtungsstelle teilzunehmen.</p>
                    <p></p><h2>Hinweis</h2>
                    <p>Die Plattform der EU zur außergerichtlichen Streitbeilegung finden Sie online unter: <a
                        href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a></p>
                    <p></p><p><em>Das Impressum wurde mit dem <a
                    href="https://www.activemind.de/datenschutz/impressums-generator/">Impressums-Generator der
                    activeMind AG</a> erstellt.</em></p>
                </Box>
            </Container>
            <Copyright/>
        </>
    );
}